.textFieldControl {
  &:global(.form-group) {
    margin-bottom: 0;
  }
  :global(.form-control) {
    border-width: 0 0 2px 0;
    flex-basis: 45%;
    flex-grow: 1;
    text-align: left;
    &:focus {
      box-shadow: none;
      border-width: 0 0 2px 0;
    }
    &:disabled {
      border-style: dotted;
    }
    &:globlal(.is-invalid) {
      border-width: 0 0 2px 0;
      background: none;
      padding-right: 0.75rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
