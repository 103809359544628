@import '../../shared.scss';

.wrapper {
  display: flex;
  width: 100%;
}

.logoContainer {
  flex: 0 0 15%;
  display: flex;
  justify-content: center;
  @include nl-media-lg {
    flex: 0 0 25%;
  }
}

.headerContainer {
  flex: 0 0 55%;
}

.organizationLogo {
  height: 37px;
  width: 37px;
  min-width: 37px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  padding: 5px;
  overflow: hidden;
}

.orgInitials {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  background-color: grey;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}

.actionContainer {
  flex: 0 0 30%;
  display: flex;
  align-items: flex-end;
  text-align: right;
}

.action {
  color: #3794fc;
  font-weight: 500;
  line-height: 18px;
  &:hover {
    color: #3794fc;
    text-decoration: none;
  }
}
