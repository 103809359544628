@import '../../shared.scss';

.container {
  :global(.nav-tabs) {
    margin-bottom: 20px;
    :global(.nav-link) {
      color: #014358;
      &:global(.active) {
        border-bottom: 1px solid #014358;
      }
    }
  }
}

.tableTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Roboto Slab', serif;
  margin-bottom: 15px;

  @include nl-media-lg {
    margin: 15px 15px 15px 0;
    font-size: 18px;
  }
}
.tabs {
  margin-top: 24px;
}
.columnText {
  display: flex;
  align-items: flex-start;
}

.tooltipStatus {
  width: 400px;
}

.langStatus {
  display: none;
  @include nl-media-md {
    display: block;
  }
}

.overlay {
  @include nl-media-md {
    display: none;
  }
}
