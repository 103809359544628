$nl-breakpoint-lg: 991px;
$nl-breakpoint-md: 576px;
$nl-breakpoint-sm: 360px;

@mixin nl-media-lg {
  @media screen and (max-width: $nl-breakpoint-lg) {
    @content;
  }
}

@mixin nl-media-md {
  @media screen and (max-width: $nl-breakpoint-md) {
    @content;
  }
}

@mixin nl-media-sm {
  @media screen and (max-width: $nl-breakpoint-sm) {
    @content;
  }
}
