.wrapper {
  margin-bottom: 30px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  h4 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
    color: #014358;
  }
  :global(.btn) {
    font-weight: 400;
    border-width: 1px;
  }
}

.originalLanguage {
  display: flex;
  padding: 20px;
  border: 1px solid rgba(1, 67, 88, 0.25);
  border-radius: 15px 15px 0px 0px;
  font-size: 20px;
  color: #014358;
  background: rgba(1, 67, 88, 0);
  :first-child {
    margin-right: 30px;
  }
}

.table {
  border: 1px solid rgba(1, 67, 88, 0.25);
  border-top: none;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
}

.tableHeaders {
  padding: 20px;
  border-bottom: 1px solid #fa812f;
  display: grid;
  grid-template-columns: 2fr 2fr 5fr 0.2fr;
  background-color: #fff;
  span {
    font-weight: 500;
    color: #014358;
  }
}

form.accordionForm {
  padding: 0;
}

.tableRow {
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr 5fr 0.2fr;
  background-color: #fff;
  cursor: pointer;
}

.isActive {
  background: rgba(1, 67, 88, 0.05);
  z-index: 0;
  cursor: default;
}

.tableColumn {
  display: inline-block;
}

.actionButtons {
  grid-column: 3;
  text-align: end;
  color: #014358;
  span svg {
    margin-right: 8px;
  }
  button {
    margin: 0 20px;
    padding: 0;
    border: none;
    color: #014358;
    background: none;
    svg {
      margin-left: 8px;
    }
  }
}

.arrowContainer {
  text-align: end;
  cursor: pointer;
}

.arrowUp {
  transform: rotate(180deg);
}

.formFields {
  padding: 20px;
  background: #f5f9fc;
  :global(.form-control),
  :global(.form-control):focus {
    border: 1px solid #ced4da;
    background-color: #fff;
  }
}

.suggestedTranslation {
  margin-bottom: 8px;
  color: #014358;
  svg {
    margin-right: 6px;
  }
}

.nameInputContainer {
  :global(.form-control) {
    border-radius: 30px;
  }
}

.descriptionInputContainer {
  margin-top: 22px;
}
