.firstTooltip,
.secondTooltip {
  position: absolute;
  z-index: 1;
  top: 4.5rem;
  left: 5rem;
  max-width: 13rem;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: #014358;
  color: #fff;
}

.firstTooltip {
  top: 5.5rem;
  left: 11.2rem;
}

.secondTooltip {
  top: 8.5rem;
  left: 15rem;
}

.flapUpTooltip,
.flapDownTooltip {
  position: absolute;
  top: -0.5rem;
  left: 2.1rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.3rem 0;
  background-color: #014358;
  transform: rotate(45deg);
}

.flapUpTooltip {
  top: -0.5rem;
  left: 2.1rem;
}

.flapDownTooltip {
  top: 8.4rem;
  left: 5.8rem;
}

.tooltipCloseButton {
  height: 1rem;
  padding: 0;
  display: flex;
  border: none;
  color: #fff;
  background: transparent;
  &:focus {
    outline: none;
  }
}

.tooltipNextButton {
  margin-left: auto;
  padding: 0 0.3rem;
  border: 0;
  font-weight: 500;
  color: #fff;
  background-color: transparent;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
}

.handDrag {
  position: absolute;
  z-index: 1;
  top: 19.5rem;
  left: 19.85rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  background-color: #014358;
  svg {
    transform: scale(2);
  }
}
