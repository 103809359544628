.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 62px;
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

.footerContainerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.divider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 0px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.dividerLine {
  width: 100%;
  border-top: 1px solid #dee2e6;
}

.searchIconStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1px;
  position: absolute;
  z-index: 99;
  right: 10px;
  width: 22px;
  height: 22px;
  background: rgba(255, 255, 255, 1);
  flex: none;
  order: 2;
  flex-grow: 0;
}

.addIconStyle {
  margin-top: -3px;
}

.iconPointer {
  cursor: pointer;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 8px;
  height: 46px;
  border-radius: 30px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  .input {
    border: 1px solid #ced4da;
    border-radius: 30px;
    width: 100%;
    :global(.form-control) {
      border: none;
    }
  }
}

.inputContainerWithButton .inputContainer {
  // Weird, I don't get it why it works like this.
  width: 100px;
}

.inputContainerWithButton {
  display: flex;
  padding: 0px 0px 8px;
  gap: 5px;
  height: 46px;
  align-self: stretch;
}

.contentInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 0;
  flex-grow: 0;
  :global(.tab-content) {
    padding-bottom: 16px;
  }
  :global(.nav-tabs) {
    width: 100%;
    :global(.nav-link) {
      margin-bottom: -1px;
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #495057;
      &:global(.active) {
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
        font-weight: 700;
        color: #014358;
      }
    }
  }
}

.contentInnerFixedWith {
  width: clamp(373px, 22vw, 400px);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.containerBorders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  left: 0px;
  top: 10px;
}

.popupPanel {
  position: relative;
  z-index: 100;
}

.checkbox label {
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  padding: 0px;
  gap: 6px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.dropdownButton,
.disabledDropdownButton {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  border-radius: 30px;
}

.dropdownButton {
  border: 1px solid #014358;
}

.disabledDropdownButton {
  border: 1px solid gray;
  pointer-events: none;
  cursor: not-allowed !important;
}

.dropdownButtonText,
.disabledDropdownButtonText {
  float: left;
  font-family: 'Roboto', sans-serif;
  font-style: 'normal';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
}

.dropdownButtonText {
  color: #013258;
}

.disabledDropdownButtonText {
  color: gray;
  pointer-events: none;
  cursor: not-allowed !important;
}

.dropdownButton:hover {
  background-color: #014358;
  cursor: pointer;
}

.dropdownButton:hover .dropdownButtonText {
  color: white;
}

.dropdownButton:hover .dropdownIcon {
  filter: invert(100%);
}

.dropdownIcon {
  height: 32px;
  opacity: 0.7;
}

.select {
  width: 100%;
}
