@import '../../shared.scss';

.customDialog {
  :global(.modal-dialog) {
    @media (min-width: 576px) {
      max-width: 1000px;
    }
  }
}

.closeIcon {
  color: #838383;
  cursor: pointer;
}

.header {
  &:global(.modal-header) {
    border: none;
    justify-content: space-between;
    align-items: center;
  }
}

.headerTitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
}
