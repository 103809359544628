.emptyTableContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 170px 0;
}

.exportCsvButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #014358;
  border-radius: 30px;
  padding: 0 10px;
}

.exportCsvButtonText {
  color: #013258;
  font-family: 'Roboto', sans-serif;
  font-style: 'normal';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.exportCsvButton:hover {
  background-color: #014358;
  cursor: pointer;
}

.exportCsvButton:hover .exportCsvButtonText {
  color: white;
}

.filterContainer {
  padding-top: 20px;
  display: flex;
}

.filterOptionsContainer {
  flex-grow: 1;
}

.itemReportsDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-top: 7px;
}

.itemReportsTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

.itemReportsText {
  color: #014358;
  font-family: 'Roboto', sans-serif;
  font-style: 'normal';
  flex-grow: 1;
}

.itemReportsTableContainer {
  background-color: #ffffff;
  border: 1px solid rgba(1, 67, 88, 0.25);
  border-radius: 15px;
}

.memberNameHeader {
  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  padding-top: 60px;
  color: #014358;
}

.spinnerContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 241px 0;
}

.summaryReportContainer {
  padding-top: 20px;
}

.itemReportsHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 15px;
}

.itemReportsControls {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  height: 100%;
}

.fulfilmentCell {
  margin-top: -8px !important;
  height: 21px;
  text-align: left;
}

.fulfilmentText {
  height: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6c757d;
}

.barChartContainer {
  margin-top: -14px !important;
}

#barChart {
  border-radius: 6px;
}

.tableTabs {
  &:global(.nav-tabs) {
    border-bottom: 1px solid #d9d9d9;
    :global(.nav-link) {
      color: #014358;
      opacity: 0.7;
      padding: 15px 30px;
    }
    :global(.nav-link.active) {
      opacity: 1;
      font-weight: 600;
      border-bottom: 2px solid #014358;
    }
  }
}
