.container {
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 0px;
  height: 48px;
  border-radius: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: inline-block;
}

.wrapper {
  display: inline-block;
}

.containerInner {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 0px 16px;
  gap: 10px;
  height: 38px;
  border: 1px solid rgba(1, 67, 88, 0.25);
  border-radius: 30px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
}

.selected {
  background: #014358;
}

.selected > .textContainer {
  :global(.prefixText) {
    color: white;
  }
  :global(.suffixText) {
    color: white;
  }
}

.selected .icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(65deg)
    brightness(102%) contrast(102%);
}
.containerInner:hover {
  border: 1px solid #014358;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
  height: 21px;
  flex: none;
  order: 0;
  flex-grow: 0;
  :global(.prefixText) {
    color: rgba(1, 67, 88, 0.5);
  }
  :global(.middleTextPositive) {
    color: #fa812f;
  }
  :global(.middleTextNegative) {
    color: #0dcaf0;
  }
  :global(.suffixText) {
    color: #014358;
  }
}

.textContainer > span {
  height: 21px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
