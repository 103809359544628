.spinnerContainer {
  display: flex;
  justify-content: center;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
}

.header {
  padding: 19px 50px 11px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.item {
  margin: 0 15px 15px 15px;
}

.claimedItemWrapper {
  border-bottom: 1px solid #e8e8e8;
}

.suggestedItemsTitle {
  margin: 30px 20px 15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
