@import '../../../theme.scss';

.wrapper {
  position: relative;
  display: flex;
}

.textWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.primaryColor {
  color: $primary;
}
.secondaryColor {
  color: $secondary;
}

.kind {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1rem;
}

.category {
  font-size: 12px;
  line-height: 1rem;
  color: #979797;
}

.name {
  font-weight: 600;
  font-size: 1.1em;
  letter-spacing: -0.5px;
  line-height: 1.1em;
  margin-bottom: 0;

  &::first-letter {
    text-transform: uppercase;
  }
}

.deliveryPending {
  font-weight: 400;
  font-size: 0.75em;
  margin: 5px 0 0;
  display: flex;
  align-items: center;
  color: #808080;
}

.organizationLogo {
  height: 104px;
  width: 104px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  overflow: hidden;
}

.code {
  font-size: 13px;
}

.menu {
  position: absolute;
  top: 0;
  right: -5px;
}

.dot {
  color: #ff8a00;
  margin-left: 5px;
  height: 8px;
  width: 8px;
}

.editContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.translateIconContainer {
  position: relative;
  cursor: default;
  top: 10px;
  padding: 15px 0 15px 15px;
  display: flex;
  align-items: flex-end;
  &:hover,
  &:focus {
    .tooltip {
      display: block;
    }
  }
}

.translateIcon {
  width: 20px;
  height: 20px;
}

.tooltip {
  top: -18px;
  right: 36px;
  left: -266px;
  display: none;
  &:hover {
    display: block;
  }
  & a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      color: #fff;
    }
  }
}

.tagContainer {
  display: flex;
  margin-top: 10px;
  height: 26px;

  .tagItem {
    border: 1px solid $primary;
    border-radius: 99px;
    padding: 3px;
    font-size: 12px;
    margin-right: 10px;
    min-width: 40px;
    text-align: center;
  }
}
