.dropdownContainer {
  position: absolute;
  top: 45px;
  left: -120px;
  min-width: 160%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1;
}

.exportButton {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 38px;
  background: #014358;
  border: 1px solid #014358;
  border-radius: 30px;
  color: white;
  cursor: pointer;
}

.exportButtonContainer {
  align-items: center;
  vertical-align: middle;
  padding: 10px 70px 10px 10px;
}
