@import '../../shared.scss';

.wrapper {
  margin-bottom: 180px;
  @include nl-media-lg {
    margin-bottom: 30px;
  }
}

.customCard {
  margin-bottom: 15px;
  padding: 40px 15px 10px;
  @include nl-media-lg {
    margin-bottom: 30px;
    padding: 10px 35px 35px;
  }
}

.customImg {
  @include nl-media-lg {
    width: 100%;
    height: auto;
  }
}

.inviteWrapper {
  flex-direction: row;
  display: flex;
  overflow: hidden;
  @include nl-media-lg {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.inviteInnerContainer {
  @include nl-media-lg {
    text-align: center;
  }
}

.inviteMembersTitle {
  margin: 50px 15px 15px;
  font-size: 40px;
  line-height: 61px;
  font-family: 'Roboto Slab', serif;
  @include nl-media-lg {
    font-size: 30px;
    margin: 20px 15px 15px;
  }
}
