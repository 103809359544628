@import './theme.scss';
@import 'bootstrap/scss/bootstrap.scss';

body {
  padding-top: 0 !important;
}

// Allows background color to be applied to all the app
html,
body,
#root {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

#root {
  display: flex;
  flex-direction: column;
}

#panel-root {
  z-index: 1040;
  position: absolute;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nl-toolbar {
  margin: 20px 0 20px 0;
  @include clearfix;
}

.nl-alert {
  margin-top: 5px;
}

.nl-login-form,
.nl-landing-form {
  background-color: #fff;
  border: lightgrey 1px solid;
  padding: 20px;
  border-radius: 0.25rem;
  margin: 100px 0 0 0;
  width: 60%;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: calc(100% - 0.75em) calc(0.375em + 0.1875rem);
}

body > div:last-child {
  z-index: 2147483647;
}

.card-columns {
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}

.nl-search-bar {
  position: relative;
  button.btn {
    position: absolute;
    right: 0;
    top: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .dropdown-menu {
    width: 100%;
  }
}

.nl-needs {
  .btn-toolbar {
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 0;
  }
  .nl-needs-search {
    margin: 0 0.4em;
  }

  .nl-needs-kind {
    margin-right: 1em;
    button {
      text-align: right;
      min-width: 10em;

      span {
        float: left;
      }
    }
  }

  .nl-needs-filters {
    button {
      min-width: 10em;
      text-align: right;

      span {
        float: left;
      }
    }
  }

  .badge {
    padding: 0.6em 0.7em;
  }
  .fa-times-circle {
    cursor: pointer;
  }
}

.nl-create-needs {
  margin-top: 1em;
  .dropdown-toggle:after {
    display: none;
  }
}

.nl-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nl-templates {
  .card {
    margin-top: 0.2rem;

    &.nl-selected {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
}

.list-group.nl-issue-response-list {
  // overflow: visible;
}

.nl-badge {
  margin-right: 5px;
  margin-bottom: 5px;
}

/**
 * Back office
 */
body {
  padding-top: 66px;
}

.table th,
.table td {
  vertical-align: middle;
}

ul.pagination {
  margin-bottom: 0px;
}

a.nav-sidebar {
  margin-bottom: 2px;
  margin-top: 2px;
}

div.list-group {
  margin-bottom: 10px;
}

/**
 * Widgets
 */
.nl-widget-address,
.nl-widget-media-image,
.nl-widget-media-video {
  .input-group,
  .custom-file {
    margin-bottom: 10px;
  }

  .card-footer,
  .card-body {
    padding: 0.7rem;
  }

  .alert,
  .nl-items {
    margin-bottom: 0.7rem;

    .list-group-item {
      padding: 0.3em 0.7em;
    }

    p.mb-1 {
      margin-top: 5px;
    }
  }
}

.nl-widget-address {
  dt {
    text-align: right;
  }
  dd {
    margin-bottom: 0.08rem;
  }
  dl {
    margin-bottom: 0;
  }
}

.input-group
  .form-control.is-invalid
  ~ .input-group-append:not(:last-child)
  > .input-group-text {
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;
}

.accordion.nl-accordion-single > .card:first-of-type {
  border-bottom: $card-border-width solid $card-border-color;
}

.accordion.nl-accordion-single > .nl-card-selected.card {
  overflow: visible;
}
.accordion.nl-accordion-single > .nl-card-selected.card .card-header {
  margin-bottom: 0;
}

.accordion.nl-accordion-single > .card {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.nl-full-width {
  width: 100%;
  text-align: right;
  display: block;
}

.back-office {
  .btn {
    padding: 9px 31px;
    font-size: 0.875rem;
    line-height: 1em;
    border-radius: 50px;
  }
}

.container {
  max-width: 1700px;
}
