@import '../../shared.scss';

.wrapper {
  position: fixed;
  top: 78.39px;
  right: 0;
  z-index: 2000;
  height: calc(100vh - 78.39px);
  display: flex;
  justify-content: flex-end;
}

.innerWrapper {
  z-index: 2000;
  overflow: auto;
  background-color: #fff;
  width: 25vw;
  min-width: 500px;
  @include nl-media-lg {
    width: 100vw;
  }
}

.container {
  z-index: 2000;
  background: white;
  padding: 50px 25px 20px 25px;
}

.hideButton {
  transform: translateY(384px);
  background-color: rgba(248, 248, 248, 1);
  cursor: pointer;
  width: 28px;
  height: 128px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidePanel {
  display: none;
}

.closeIcon {
  position: absolute;
  z-index: 2000;
  top: 20px;
  right: 30px;
  color: #838383;
  cursor: pointer;
}
