@import '../../shared.scss';

.cardContainer {
  margin: 60px 30px 15px 30px;
  padding: 10px 0;
  @include nl-media-lg {
    &.card {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
    margin-bottom: 0;
  }
}
