.spinnerContainer {
  display: flex;
  justify-content: center;
}

.claimedList {
  padding-top: 21px;
  margin: 0 -18px;
}

.claimDetailsWrapper {
  margin-top: 50px;
}

.imageUploadWrapper {
  margin-bottom: 10px;
}

.claimInformationTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.message {
  margin-top: 15px;

  &:last-child {
    margin-bottom: 50px;
  }
}

.claim {
  margin: 15px 0 30px;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
}

.thumbnail {
  width: 45px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 5px;
}

.division {
  margin-bottom: 30px;
}

.messageWrapper {
  display: flex;
  width: 100%;
}

.messageBox {
  padding: 12px 25px;
  flex: 1;
}

.messageBoxBackground {
  background: rgba(115, 195, 221, 0.25);
  border-radius: 0px 15px 15px 15px;
}

.invertBackground {
  background: #d2def0;
  border-radius: 15px 0px 15px 15px;
}

.date {
  color: #909090;
  font-size: 12px;
}

.senderContainer {
  flex: 0 0 15%;
  display: flex;
  justify-content: center;
}

.senderImage {
  height: 37px;
  width: 37px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  padding: 5px;
  overflow: hidden;
}
