@import '../../theme.scss';
@import '../../shared.scss';

.wrapper {
  margin: 30px 40px 0;
  color: $primary;
  @include nl-media-lg {
    margin: 30px 15px 0;
  }
}

.itemsWrapper {
  position: relative;
  width: 100%;
  min-height: 750px;
  display: flex;
}
