@import '../../../shared.scss';

.customForm {
  display: flex;
  padding: 50px 0;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  @include nl-media-lg {
    padding: 0 0 25px;
  }
}
