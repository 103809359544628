@import '../../theme.scss';

.totalsRowContainer {
  &:global(.row) {
    margin: 0;
    border-bottom: 1px solid #d9d9d9;
  }
}

.totalsContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px 0 7px 15px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  line-height: 150%;
  color: #014358;
}

.totalsText {
  border-right: 2px dashed #d9d9d9;
}

.totalsAmount {
  background: $body-bg;
  border-left: 2px solid $secondary;
}

.totalsData {
  font-family: 'Roboto Slab', serif;
  margin-top: -3px;
  font-size: 24px;
  font-weight: 700;
}

.textOrange {
  margin-top: -3px;
  color: $secondary;
}
