.modal {
  :global(.modal-dialog) {
    max-width: 700px;
  }

  :global(.modal-body) {
    padding: 0px;
  }
}
.locationForm {
  padding: 17px 30px;

  label {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }

  :global(.form-control) {
    padding: 0;
    border: none;
    border-bottom: 1px solid #efefef;
    color: #9e9e9e;
    &.editableField {
      color: #000;
      &::placeholder {
        color: #000;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .modalButton {
    padding: 7px 13px;
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
  }
}
.modalInfo {
  padding: 17px 30px;
  background-color: rgba(1, 67, 88, 0.03);
  display: flex;
  align-items: center;
  color: #014358;
  .modalInfoText {
    font-size: 16px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 20px;
    p {
      margin: 0;
    }
  }
}
