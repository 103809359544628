@import '../../shared.scss';

.rowData {
  display: flex;
  justify-content: center;
  padding: 19px 15px 11px;
  border-bottom: 1px solid #e8e8e8;
}

.wrapper {
  margin-bottom: 30px;
}

.cardsListPaginator {
  margin-top: 20px;

  @include nl-media-lg {
    margin-top: 15px;
    justify-content: center;
    overflow: auto;
  }
}
