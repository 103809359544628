.container {
  height: 100px;
  width: 100px;
  font-size: 3rem;
  border-radius: 90px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  background-color: grey;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}
