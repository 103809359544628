@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

.addressBoxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > :global(.card) {
    cursor: pointer;
    min-height: 6.75rem;
    background-color: #fbfbfb;
    padding: 0.93rem 1rem 0.625rem;
    width: 100%;

    align-items: flex-start;

    flex: 1 0 calc(50% - 0.625rem * 2);
    margin: 0.31rem;
    @include media-breakpoint-down(md) {
      flex: none;
    }

    &.addressBoxAdd {
      justify-content: center;
      align-items: center;

      & > :global(.card-body) {
        font-weight: 600;
        font-size: 1rem;
        color: #014358;
        flex-grow: initial;
      }
    }

    &.addressBoxEmpty {
      background: #ffffff;
      border: 0.063rem dashed #dddddd;
    }
  }
}

.addressBoxTools {
  position: absolute;
  right: 0;
  top: 0;

  :global(.btn) {
    padding: 0 0.625rem;
    margin-right: 0.125rem;
  }
}

.addressBoxEmpty {
  background: #ffffff;
  border: 0.063rem dashed #dddddd;
}

.addressBox {
  justify-content: space-between;

  &:hover,
  &.selected {
    border-color: #014358;
  }

  &Badge {
    margin-top: 0.31rem;
    font-size: 0.75rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    color: #014358;
  }

  &Title {
    margin: 0;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 600;
  }

  &Address {
    margin: 0;
    font-size: 0.87rem;
    line-height: 1.375rem;
    text-overflow: ellipsis;
  }
}
