@import '../../../../shared.scss';

.customCard {
  margin-bottom: 15px;
  padding: 40px 15px 10px;
  @include nl-media-lg {
    &:global(.card) {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
    margin-bottom: 0;
  }
}

.tableTitle {
  margin: 50px 15px 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Roboto Slab', serif;
  @include nl-media-lg {
    margin: 15px 15px 15px 0;
    font-size: 18px;
  }
}

.boldText {
  font-weight: bold;
}
