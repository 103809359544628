@import '../../theme.scss';

.stepperContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.stepperTitle {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $primary;
  margin-bottom: 20px;
}
.stepperHeader {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepperHeaderItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .stepCounter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    padding: 10px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }

  &::after {
    position: absolute;
    content: '';
    border-bottom: 2px dashed #ccc;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 2;
  }

  &.processing {
    .stepCounter {
      background: white;
      border: 1px solid #4bb543;
      .stepCounterProcessing {
        position: absolute;
        background-color: #4bb543;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
    &::before {
      position: absolute;
      content: '';
      border-bottom: 2px solid #4bb543;
      width: 100%;
      top: 10px;
      left: -50%;
      z-index: 3;
    }
  }
  &.completed {
    .stepCounter {
      background-color: #4bb543;
    }
    &::before {
      position: absolute;
      content: '';
      border-bottom: 2px solid #4bb543;
      width: 100%;
      top: 10px;
      left: -50%;
      z-index: 3;
    }
  }

  &:first-child {
    &::before {
      content: none;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}
