@import '../../shared.scss';
@import './AddItems.module.scss';

.fieldOfferKind {
  max-width: 540px;
  margin: 0 auto;
  width: 100%;
}

.fieldOffers {
  margin: 0 auto;

  width: 460px;
  padding-bottom: 53px;
  @include nl-media-lg {
    width: 100%;
    padding-bottom: 15px;
  }
}
