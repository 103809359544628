@import '../../shared.scss';
@import './AddItems.module.scss';

.pills {
  padding: 20px 130px;

  @include nl-media-lg {
    padding: 0;
  }
}
