@import '../../shared.scss';

.shareButton {
  align-self: flex-end;
}

.newLinkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.warning {
  font-size: 0.8rem;
  color: #979797;
  margin: 0;
}

.confirmButtonsContainer {
  display: flex;
  @include nl-media-lg {
    flex-direction: column;
  }
}

.confirmButton {
  margin: 0 10px;
  @include nl-media-lg {
    margin: 5px 0;
  }
}

.copiedMsgWrapper {
  height: 24px;
}

.copiedMsg {
  color: green;
  animation: fadeOut 2s;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
