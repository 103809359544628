@import '../../../../theme.scss';

.option {
  display: flex;
}

.label {
  cursor: pointer;
  display: flex;
  line-height: 22px;
}

.radioIcon {
  border: 2px solid rgba(1, 1, 1, 0.54);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  ~ input:checked {
    background-color: #000;
  }
}

.circle {
  background-color: $primary;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: none;
}

.radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ .radioIcon {
    border-color: $primary;
    .circle {
      display: block;
    }
  }
}

.choiceControl {
  &:global(.form-group) {
    margin-bottom: 0;
  }
}
