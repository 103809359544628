.addFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 48px;
  flex: none;
  order: 3;
  flex-grow: 0;
  display: inline-block;
}

.wrapper {
  display: inline-block;
}

.addFilterInnerContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 13px;
  gap: 4px;
  height: 38px;
  border: 1px dashed #014358;
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.addFilterInnerContainer:hover,
.selected {
  background: #014358;
}

.addFilterInnerContainer:hover .addFilterText,
.selected .addFilterText {
  color: #ffffff;
}

.addFilterInnerContainer:hover .icon,
.selected .icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(65deg)
    brightness(102%) contrast(102%);
}

.addFilterTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  height: 24px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.addFilterText {
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #014358;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.addFilterIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 9.6px;
  height: 9.6px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.popupContainer {
  position: absolute;
  width: 805px;
  z-index: 100;
}

.popupContainerInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 3px;
  position: absolute;
  width: 300px;
  left: 0px;
  top: 0px;
}

.subFilterContainer {
  position: absolute;
  left: 300px;
}

.popupList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 10px;
}

.popupListItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 16px;
  height: 40px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.125);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
}
.popupListItem:hover {
  background: #f8f9fa;
}

.popupListItem > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 268px;
  height: 24px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.popupListItem > div > span {
  width: 268px;
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
