@import '../../../../theme.scss';

.inputLabel {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    path {
      fill: $primary;
    }
  }
  b {
    font-weight: 500;
  }
  label {
    margin-bottom: 0;
  }
}

.changePlace {
  color: $secondary;
  cursor: pointer;
}

.searchInput {
  input {
    border-radius: 20px;
    background-color: #fff;
    &:focus {
      background-color: #fff;
    }
  }
  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 14px;
    top: 7px;
    path {
      fill: $primary;
    }
  }
}

.customInput {
  position: relative;
  input {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    padding: 6px 12px;
    &:focus {
      outline: none;
      border-color: $primary;
      box-shadow: 0 0 0 0.2rem rgba(1, 67, 88, 0.25);
    }
  }
  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 14px;
    top: 7px;
    path {
      fill: $primary;
    }
  }
}
