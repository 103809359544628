.container {
  position: absolute;
  z-index: 10;
  background-color: rgba(1, 67, 88, 1);
  color: #fff;
  padding-top: 25px;
  padding-left: 25px;
  border-radius: 8px;
}

.firstTooltip {
  top: 95px;
  left: 335px;
  width: 280px;
}

.secondTooltip {
  left: 335px;
  bottom: 80px;
  width: 260px;
}

.lastTooltip {
  width: 320px;
  top: -70px;
  left: -15px;
}

.tooltipText {
  padding-bottom: 8px;
  padding-right: 30px;
  line-height: 20px;
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.text {
  font-size: 12px;
  font-weight: 400;
}

.textBold {
  padding-bottom: 8px;
  padding-right: 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.button {
  padding-bottom: 16px;
  padding-right: 16px;
  text-align: right;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;
}

.triangle {
  position: absolute;
  top: 35px;
  left: 253px;
  width: 20px;
  height: 20px;
  border-radius: 5px 0;
  background-color: #014358;
  transform: rotate(45deg);
}
