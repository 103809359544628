@import '../../../theme.scss';

.wrapper {
  :global(.form-control) {
    border-width: 2px;
    border-bottom-color: $primary;
    resize: none;
    background-color: white;
    &:focus {
      box-shadow: none;
      background-color: white;
    }
    &.is-invalid {
      background: red;
    }
  }
}
