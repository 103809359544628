@import '../../shared.scss';

.wrapper {
  margin-top: 20px;
  :global(.col) {
    margin-bottom: 1rem;
  }
  .formFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include nl-media-lg {
      flex-direction: column;
      justify-content: center;
    }
    :global(.btn) {
      margin-left: 10px;
      @include nl-media-lg {
        margin-left: 0;
        margin-top: 10px;
        width: 230px;
      }
    }
  }
}
