.fileSpan {
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.fileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.94rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.removeFileIcon {
  color: #fc381d;
  cursor: pointer;
}
