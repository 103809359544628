.award {
  color: #454545;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
}

.awardIcon {
  margin-right: 5px;
}
