.pagerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0px;
  height: 54px;
}

.summaryText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #adb5bd;
}

.selectPageSizeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 31px;
  order: 2;
}

.preDropdownText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #adb5bd;
}

.dropdownContainer {
  flex: none;
}

.dropdown {
  padding: 0px 9px;
  height: 31px;
  border-radius: 30px !important;
}

.divPrev {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  width: 30px;
  height: 31px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #dee2e6;
  border-radius: 50px 0px 0px 50px;
  flex: none;
  flex-grow: 0;
  margin-right: 0px;
}

.divPager {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 31px;
  background: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.icon {
  color: #014358;
}

.disabledArrow .icon {
  color: gray;
}

.activeArrow {
  cursor: pointer;
}

.divItem {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  height: 31px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #dee2e6;
  flex: none;
  flex-grow: 0;
  margin-left: -1px;
}

.textItem {
  height: 21px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #014358;
  flex: none;
  flex-grow: 0;
  cursor: pointer;
}

.divItem > .active {
  cursor: default !important;
  font-weight: 800;
}

.divEllipsis {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 9px 5px 8px;
  width: 31px;
  height: 31px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #dee2e6;
  flex: none;
  flex-grow: 0;
  margin-left: -1px;
}

.divNext {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  width: 30px;
  height: 31px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #dee2e6;
  border-radius: 0px 50px 50px 0px;
  flex: none;
  flex-grow: 0;
  margin-left: -1px;
}
