.here {
  text-decoration: underline black;
  cursor: pointer;
}

.linkMessage {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  &:global(.alert) {
    border-radius: 30px;
    border-color: #1d4d83;
    padding: 0.35rem 0.75rem;
  }
}

.buttonClose {
  cursor: pointer;
  position: absolute;
  right: 15px;
  font-size: 15px;
}
