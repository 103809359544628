.rowData {
  padding: 19px 15px 11px;
  border-bottom: 1px solid #d9d9d9;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.isClickeable {
  cursor: pointer;

  :hover {
    background: #f5f9fc;
  }
}

.viewDetails {
  cursor: pointer;
  color: #3794fc;
}
