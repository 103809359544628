@import '../../../../shared.scss';

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  justify-content: flex-end;
}

.innerWrapper {
  z-index: 2000;
  width: auto;
  overflow: auto;
  background-color: transparent;
  @include nl-media-lg {
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
  }
}

.container {
  z-index: 2000;
}

.hidePanel {
  display: none;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 3000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #838383;
  cursor: pointer;
  background-color: rgba(248, 248, 248, 1);
}
