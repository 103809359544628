@import '../../shared.scss';

.wrapper {
  margin-bottom: 30px;
}

.headers {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.columnNames {
  padding: 30px 15px 11px;
  border-bottom: 1px solid #fa812f;
  border-top: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  color: #014358;
  font-family: 'Roboto', sans-serif;
  font-style: 'normal';
  text-transform: uppercase;
}

.paginator {
  margin: 20px 10px 0 0;

  @include nl-media-lg {
    margin-top: 15px;
    justify-content: center;
    overflow: auto;
  }
}

.sortIcon {
  margin-right: 5px;
}
