@import '../../../../theme.scss';
@import '../../../../shared.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  @include nl-media-lg {
    z-index: 30;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refreshButton {
  position: absolute;
  top: 15px;
  z-index: 10;
  left: 50vw;
  @include nl-media-lg {
    left: 15px;
  }
  :global(.btn) {
    padding: 5px 25px;
    font-size: 16px;
    font-weight: 400;
    color: $primary;
    @include nl-media-lg {
      font-size: 14px;
    }
    svg {
      margin-left: 5px;
      path {
        fill: $primary;
      }
    }
  }
}

.loadingDiv {
  position: absolute;
  display: flex;
  z-index: 10;
  top: 50%;
  left: 50%;
}

.hitsWrapper {
  max-width: 320px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding-top: 30px;
  padding-left: 0;
  @include nl-media-lg {
    max-width: none;
    padding: 50px 30px 0;
  }
}

.hitsList {
  width: 100%;
  @include nl-media-lg {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.hitsItem {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: none;
  box-shadow: none;
  @include nl-media-lg {
    max-width: 400px;
    margin-right: 15px;
  }
}

.hitsButton {
  height: auto;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 16px;
  font-weight: 400;
  color: $primary;
  background: #f8f9fa;
  &:hover {
    border-color: #dae0e5;
    color: #212529;
    background: #e2e6ea;
  }
  &:focus {
    border-color: #d6d6e7;
    box-shadow: none;
  }
}

.hitsEmpty {
  display: none;
}
