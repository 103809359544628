.toastContainer {
  background: #e8f4db;
  border-radius: 10px;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  padding: 15px 20px;
  width: 100%;
}

.toastTitle {
  color: #728956;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 24px;
  margin-bottom: auto;
  text-align: left;
}

.toastText {
  color: #728956;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
