@import '../../../shared.scss';
@import '../../../theme.scss';

.container {
  padding: 50px 30px 30px;
  @include nl-media-lg {
    padding: 20px 0;
  }
}

// MyItems
.addButton {
  &:global(.btn) {
    min-width: 213px;
    padding: 7px 25px;
    @media screen and (max-width: 1122px) {
      font-size: 14px;
    }
  }
}

.filters {
  margin-bottom: 50px;
  @include nl-media-lg {
    margin-bottom: 30px;
  }
}

.filtersHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include nl-media-lg {
    display: block;
  }
}

.itemSearchBar {
  border-radius: 0;
  border-bottom: 2px solid $primary;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 60%;
  @include nl-media-lg {
    width: 100%;
  }
}

.itemsPaginator {
  margin-top: 20px;
  @include nl-media-lg {
    margin-top: 15px;
    justify-content: center;
    overflow: auto;
  }
}
