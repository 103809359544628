@import '../../shared.scss';

.dialog {
  padding: 0;
  @media (max-width: 576px) {
    max-width: 576px;
  }
  :global(.modal-header) {
    font-size: 1.1rem;
    font-weight: bold;
  }
  :global(.modal-body) {
    text-align: center;
  }
}

.title {
  font-weight: 600;
  font-size: 1.8rem;
  @include nl-media-lg {
    font-weight: 500;
    font-size: 20px;
  }
}

.yorkTitle {
  font-family: 'Roboto Slab', serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.text {
  font-size: 0.8em;
  line-height: 1.8em;
  padding: 0 20px;
  margin-bottom: 50px;
  @include nl-media-lg {
    margin-bottom: 15px;
  }
}

.boldText {
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 13px;
  @include nl-media-lg {
    margin-bottom: 5px;
  }
}

.typeaheadContainer {
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 10px;
}

.dialogButton {
  margin: 0 10px;
  &:global(.btn) {
    padding: 7px 45px;
  }
  @include nl-media-lg {
    margin-bottom: 5px;
  }
}

.errorMessageContainer {
  padding-bottom: 30px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @include nl-media-lg {
    flex-direction: column;
  }
  :global(.btn) {
    padding: 7px 20px;
    font-size: 16px;
  }
}

.reasonTextArea {
  resize: none;
}

.closeIcon {
  color: #838383;
  cursor: pointer;
}

.label {
  font-size: 1rem;
  font-weight: bold;
}

.checkIcon {
  color: #26a69a;
  font-size: 85px;
  margin: 45px 0 30px;
}

.circleExclamation {
  margin: 45px 0 30px;
  path {
    fill: white;
  }
}
