.bar {
  background: #eeeeee;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 6px;
  overflow: hidden;
}

.progress {
  height: 6px;
  background-color: #55acee;
  border-radius: 6px;
}

.quantity {
  text-align: right;
  font-size: 13px;
  margin-bottom: 3px;
}

.show {
  display: block;
}

.hide {
  display: none;
}
