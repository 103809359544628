@import '../../../shared.scss';

.checkIconToast {
  border-radius: 50%;
  color: #0f5132;
  font-size: 24px;
  margin-right: 10px;
}

.closeIcon {
  opacity: 0.6;
  cursor: pointer;
  margin-right: -15px;
}

.circleExclamation {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  path {
    stroke: #fa812f;
    fill: white;
  }
}

.dialogErrorText {
  width: 250px;
}

.dialogHeader {
  color: #014358;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 10px;
  word-break: break-word;
}

.dialogInputDescription {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #212529;
  padding-bottom: 10px;
}

.dialogInputDescription > span {
  color: #dc3545;
}

.dialogInput,
.invalidDialogInput {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 13px;
  width: 260px;
  height: 38px;
  background: #ffffff;
  border-radius: 30px;
}

.invalidDialogInput {
  border: 1px solid #fc381d;
}

.dialogInput {
  border: 1px solid #ced4da;
}

.dialogErrorMessage {
  color: #fc381d;
  display: flex;
  padding-top: 5px;
  font-size: 13px;
}

.dialogHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
}

.dialogActionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 13px;
  gap: 8px;
  height: 38px;
  background: #014358;
  border-radius: 30px;
  color: white;
}

.dialogActionButton:hover {
  cursor: pointer;
}

.dialogButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 70px;
  align-items: center;
}

.dialogButtonContainer > * {
  margin-right: 10px;
}

.dialogCloseButton {
  box-sizing: border-box;
  color: #014358;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 13px;
  gap: 8px;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #014358;
  border-radius: 30px;
}

.dialogCloseButton:hover {
  background-color: #014358;
  cursor: pointer;
}

.dialogCloseButton:hover .dialogCloseButtonText {
  color: white;
}

.dropdownContainer {
  position: absolute;
  top: 218px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1;
}

.dropdownListItem,
.selectedDropdownListItem,
.disabledDropdownListItem,
.selectedEditedDropdownListItem {
  display: flex;
  flex-direction: row;
  padding: 7px 5px 7px 12px;
  gap: 8px;
  background: rgba(255, 255, 255, 1e-6);
  word-wrap: break-word;
  width: 204px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c8c8c8;
}

.dropdownListItemText {
  word-wrap: break-word;
  width: 150px;
}

.dropdownListItem:hover {
  background: #ededed;
}

.deleteIcon,
.selectedViewDeleteIcon {
  padding-right: 10px;
  height: 20px;
}

.deleteIcon {
  opacity: 0.7;
}

.disabledDropdownListItem {
  pointer-events: none;
  color: gray;
  cursor: not-allowed !important;
}

.dropdownListHeader {
  padding: 16px 16px 8px;
  width: 204px;
  gap: 8px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #014358;
  word-wrap: break-word;
}

.dropdownListIcon {
  height: 32px;
  opacity: 0.7;
}

.disabledDropdownListIcon {
  height: 32px;
  opacity: 0.3;
}

.errorIcon {
  fill: #fc381d;
  margin: 3px 7px 0 8px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @include nl-media-lg {
    flex-direction: column;
  }
}

.saveViewButton {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #014358;
  border-radius: 30px;
}

.saveViewButtonText {
  float: left;
  color: #013258;
  font-family: 'Roboto', sans-serif;
  font-style: 'normal';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
}

.saveViewButton:hover {
  background-color: #014358;
  cursor: pointer;
}

.saveViewButton:hover .saveViewButtonText {
  color: white;
}

.saveViewButton:hover .dropdownIcon {
  filter: invert(100%);
}

.selectedViewDeleteIcon {
  filter: invert(100%);
}

.selectedDropdownListItem {
  color: white;
  background: #014358;
}

.selectedEditedDropdownListItem {
  color: white;
  background: #fa812f;
}

.toastContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  width: 400px;
  height: 56px;
  background: #d1e7dd;
  border: 1px solid #badbcc;
  border-radius: 50px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: absolute;
  top: 10px;
  left: 35%;
}

.toastText {
  width: 304px;
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #0f5132;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.unsavedChanges {
  color: #fa812f;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
  width: 180px;
}

.unsavedChangesContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.warningDialog {
  display: flex;
  flex-direction: column;
  padding: 0px;
  min-width: 410px;
  min-height: 174px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  position: absolute;
  top: 0;
  left: -620px;
}

.saveNewViewDialog {
  display: flex;
  flex-direction: column;
  padding: 0px;
  min-width: 350px;
  min-height: 174px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  position: absolute;
  top: 0;
  left: -560px;
}

.saveNewViewDialogContainer {
  display: flex;
  flex-direction: row;
  padding: 15px 5px 20px 0;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
}

.warningContainer {
  display: flex;
  flex-direction: row;
  padding: 15px 5px 20px 20px;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
}

.dialogTextContainer {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 10px;
}

.warningDescription {
  font-weight: 400;
  padding-top: 5px;
}

.warningHeader,
.warningDescription {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
}

.warningHeader {
  font-weight: 700;
}

.warningIcon {
  font-size: 50px;
  path {
    fill: #edc459;
  }
}
