@import '../../../../shared.scss';

.container {
  width: 307px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

  @media (max-width: 576px) {
    width: 330px;
  }

  .cardFooter {
    font-size: smaller;
  }

  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }

  .iconEdit {
    position: absolute;
    top: 1rem;
    right: 3rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }

  :global(.card-body) {
    padding: 23px 26px;
  }

  :global(.card-title) {
    margin-bottom: 27px;
    font-size: 17px;
    font-family: 'Roboto Slab', serif;
  }

  :global(.form-group:nth-of-type(2)) {
    margin-bottom: 0;
  }

  :global(.form-group:nth-of-type(3)) {
    margin-top: 25px;
  }

  :global(.form-label) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  :global(.form-group input) {
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 16px;
    background-size: 16px;
  }

  :global(.form-control) {
    height: 49px;
    padding-left: 37px;
    border-radius: 8px;
    border-color: #858c94;
    &:global(.is-invalid) {
      background-position: calc(100% - 0.75em);
    }
  }

  :global(.form-group:nth-of-type(1) input:nth-of-type(2)) {
    background-image: url('../../../../assets/icons/search-box-map-pin.png');
  }

  :global(.form-group:nth-of-type(2) input:nth-of-type(2)) {
    background-image: url('../../../../assets/icons/search-box-search.png');
  }

  :global(.form-check) {
    margin-bottom: 14px;
    label {
      cursor: pointer;
    }
  }

  :global(.form-check-input) {
    display: none;
  }

  .checkbox {
    margin-right: 17px;
  }

  .needCheckbox {
    color: #014358;
    font-weight: 500;
  }

  .offerCheckbox {
    color: #fa812f;
    font-weight: 500;
  }

  .manufacturerCheckbox {
    color: #b84e74;
    font-weight: 500;
  }

  :global(.btn) {
    width: 100%;
    text-transform: uppercase;
    border-radius: 9px;
  }

  .activeTooltip {
    background-color: #f8f8f8;
  }
}
