@import '../../../theme.scss';

.customText {
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.removeIcon {
  color: $danger;
  cursor: pointer;
}

.avatarContainer {
  height: 100px;
  width: 100px;
  border-radius: 90px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
