.locationStyles {
  font-weight: 600;
  font-size: 14px;
  color: #454545;
  line-height: 16px;
}

.location {
  overflow: hidden;
  max-height: 38px;
  margin-bottom: 0;
}

.locationIcon {
  margin-right: 5px;
}
