.spinnerContainer {
  display: flex;
  justify-content: center;
}

.center {
  padding: 28px 0;
}

.link {
  margin-top: 10px;
  text-decoration: underline;
}
