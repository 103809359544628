.spinnerContainer {
  display: flex;
  justify-content: center;
}

.informationWrapper {
  margin-bottom: 28px;
}

.center {
  padding: 28px 0;
}

.link {
  margin-top: 10px;
  text-decoration: underline;
}

.shareContainer {
  display: flex;
  justify-content: flex-end;
}
