@import '../../../theme.scss';

.container {
  position: relative;
}

.customTypeahead {
  :global(.rbt-input-multi.focus),
  :global(.rbt-input-multi:focus),
  :global(.form-control:focus) {
    box-shadow: none;
    border-color: $primary;
    border-width: 0 0 2px 0;
  }
  input,
  :global(.rbt-input-multi) {
    border-width: 0 0 2px 0;
    border-radius: 0;
    outline: none;
    padding-right: 30px;
  }
  :global(.rbt-input-hint) {
    bottom: 0;
    margin: 0;
    input {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  :global(.rbt-input-wrapper) {
    display: flex;
    flex-wrap: row;
  }
  :global(.dropdown-item) {
    :hover {
      background-color: #014358;
      text-decoration: none;
      color: #fff;
    }
  }
  :global(.rbt-menu) {
    max-height: 155px !important;
  }
}

.searchIcon {
  position: absolute;
  right: 10px;
  bottom: 0;
  top: 0;
  margin: auto;
}

.cleanIcon {
  cursor: pointer;
}

.customFeedback {
  &.invalid-feedback {
    display: block;
  }
}

.errorIcon {
  margin-right: 7px;
}
