.toast {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #3794fc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.toastTitle {
  padding: 14px 21px 0px 22px;
  margin-bottom: auto;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.toastText {
  padding: 14px 22px 14px 0px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
