@import '../../shared.scss';

.customDialog {
  @include nl-media-md {
    &:global(.modal-dialog) {
      max-width: 576px;
    }
  }
}

.header {
  font-size: 1.1rem;
  font-weight: bold;
}

.body {
  text-align: center;
}

.checkIcon {
  color: #26a69a;
  font-size: 85px;
  margin: 45px 0 30px;
  @include nl-media-lg {
    font-size: 28px;
    margin: 70px 0 20px;
  }
}

.title {
  font-weight: 600;
  font-size: 1.8rem;
  @include nl-media-lg {
    font-weight: 500;
    font-size: 20px;
  }
}

.content {
  font-size: 0.8em;
  line-height: 1.8em;
  padding: 0 20px;
  margin-bottom: 50px;
  @include nl-media-lg {
    margin-bottom: 15px;
  }
}

.footer {
  margin-bottom: 50px;
}

.primaryButton {
  margin-bottom: 15px;
}
