@import '../../../../shared.scss';

.container {
  width: 75%;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  @include nl-media-lg {
    width: 100%;
    margin-left: 40px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  text-align: center;
}

.hitsWrapper {
  margin: 10px 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  column-gap: 20px;
  list-style: none;
  @include nl-media-lg {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
