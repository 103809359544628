.header {
  padding: 19px 103px 11px 15px;
  border-bottom: 1px solid #e8e8e8;
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
  }
}

.formTitle {
  padding: 15px 15px;
  font-size: 22px;
}

.formWrapper {
  margin: 0 15px;
}

.spinnerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
