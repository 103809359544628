.wrapper {
  cursor: pointer;
  :global(.dropdown-toggle) {
    &:after {
      display: none;
    }
  }
  :global(.dropdown-menu) {
    background: #fafafa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
    padding: 20px 0;
  }
}

.menuDots {
  padding: 0 5px;
}

.dots {
  color: rgba(0, 0, 0, 0.5);
}
