@import '../../shared.scss';

.wrapper {
  margin-bottom: 180px;

  @include nl-media-lg {
    margin-bottom: 30px;
  }
}

.title {
  margin-bottom: 24px;
}
