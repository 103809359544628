.organizationLogo {
  width: 55px;
  height: 55px;
  border: 4px solid #eeeeee;
  border-radius: 50%;
  font-size: 25px;
  overflow: hidden;
}

.itemStatus {
  color: #979797;
  margin-bottom: 9px;
  font-weight: bold;
  flex: 1;
  text-align: right;
  font-size: 13px;
  font-style: italic;
  padding-right: 15px;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 55px;
}
