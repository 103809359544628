.modal {
  :global(.modal-dialog) {
    max-width: 700px;
  }

  :global(.modal-body) {
    padding: 0px;
  }
}
.searchControls {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.searcher {
  width: 85%;
  height: 40px;
  z-index: 2;
  top: 25px;
  left: 0;
  right: 0;
  text-align: center;

  background: #ffffff;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.17);
  border-radius: 60px;
}

.searcherInput {
  width: 100%;
  margin-right: 5px;
}
.closeIcon,
.questionIcon {
  z-index: 2;
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.17);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  right: 35px;
  &:hover {
    color: #fff;
    background-color: #e54d04;
  }
}

.questionIcon {
  right: 78px;
  &:hover {
    color: #fff;
    background-color: #6bb1ff;
  }
}
.mapOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 27.5rem;
  background: rgba(85, 172, 238, 0.5);
}

.locationForm {
  padding: 17px 10px;

  label {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }

  :global(.form-control) {
    padding: 0;
    border: none;
    border-bottom: 1px solid #efefef;
    color: #9e9e9e;
    &.editableField {
      color: #000;
      &::placeholder {
        color: #000;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.footerButtons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 12px;
  .modalButton {
    padding: 7px 13px;
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
  }
}

.fullAddress {
  background-color: rgba(1, 67, 88, 0.03);
  padding: 17px 30px;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
}
.locationIcon {
  height: 32px;
}
.fullAddressData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.addressStreet {
  color: #014358;
  font-size: 20px;
  line-height: 1;
}
.warningAddress {
  display: flex;
  align-items: center;
  padding: 14px 15px;
  font-size: 14px;
  color: #014358;
  p {
    margin: 0;
  }
  .warningIcon {
    margin-right: 8px;
  }
  .fillForm {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
  }
}

.closeIconContainer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
}
