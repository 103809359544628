body {
  .cardDeck {
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
    .CardRow {
      height: 100%;
    }
    .CardData {
      display: grid;
    }
    .cardContainer {
      margin-bottom: 1rem;
      @media screen and (min-width: 768px) {
        min-width: 40%;
      }
      @media screen and (min-width: 1025px) {
        min-width: 21%;
      }
    }
    .cardTotalMatches {
      margin-bottom: 1rem;
      @media screen and (min-width: 768px) {
        min-width: 40%;
      }
      @media screen and (min-width: 1025px) {
        min-width: 21%;
      }
      @media screen and (min-width: 1201px) {
        min-width: 16%;
        max-width: 16%;
        margin-right: -14px;
      }
    }
  }
}

.spinnerContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 5.625rem;
  background: #f6f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border: 1px solid #014358;
}

.miniIcon {
  color: #014358;
  font-size: 8px;
  position: relative;
  top: 10px;
  left: 0;
}

.bigIcon {
  padding-left: 6px;
}

.cardText {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: 'normal';
  font-size: 16px;
  color: #014358;
  text-align: right;
  @media screen and (max-width: 74.9375em) {
    padding-left: 0;
  }
}

.cardAmount {
  margin: 0;
  font-weight: 400;
  color: #014358;
  text-align: right;
  font-family: 'Roboto Slab', serif;
  font-style: 'normal';
  font-size: 24px;
  grid-row-start: 9;
}

.currencySymbol {
  font-size: 24px;
  font-weight: 600;
  color: #014358;
}
