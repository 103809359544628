@import '../../shared.scss';

.main_auth_div {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  // width: 460px;
  width: 100%;
  height: calc(100% - 50px);
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}
.main_auth_div_2 {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 32px;
  width: 460px;
  height: calc(100% - 230px);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.main_auth_div_3 {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 360px;
  height: calc(100% - 330px);
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.main_auth_header {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  width: 360px;
  height: 149px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.main_auth_header_logo {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 18px;
  width: 240px;
  height: 62px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.main_auth_header_text {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 360px;
  height: 63px;
  /* HitBox */
  background: rgba(255, 255, 255, 1e-6);
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.main_auth_header_text_line1 {
  width: 197px;
  height: 27px;
  /* (h2) Sub Category - bold */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Theme/Primary */
  color: #014358;
}
.main_auth_header_subtitle {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 332px;
  height: 24px;
  /* HitBox */
  background: rgba(255, 255, 255, 1e-6);
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.main_auth_header_subtitle_text {
  width: 332px;
  height: 24px;
  /* Body/Regular */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  /* Gray/600 */
  color: #6c757d;
}
.needslist_logo {
  position: absolute;
  width: 211px;
  height: 38px;
  left: 50px;
  bottom: 50px;
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error_message_div {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
  // padding: 0px;
  // gap: 12px;
  width: 360px;
  // /* HitBox */
  // background: rgba(255, 255, 255, 1e-6);
  // /* Inside auto layout */
  // flex: none;
  // order: 2;
  // align-self: stretch;
  // flex-grow: 0;
}
.error_message_header {
  /* (h2) Sub Category - bold */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Theme/Primary */
  color: #014358;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.error_message {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  /* Theme/Primary */
  color: #014358;
  margin-top: 8px;
}

.logo {
  width: 30vw;
  height: 8vh;
  animation: 1.4s linear 1s infinite alternate opacity;

  @keyframes opacity {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
.register_form_footer {
  margin-top: auto;
  text-align: center;
}
.register_form_fields_container {
  display: flex;
  flex-flow: wrap;
  margin-top: auto;
}
.register_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 360px;
  height: 192px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  @include nl-media-lg {
    padding: 15px 0;
    @keyframes opacity {
      0% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
