.buttonContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 13px;
  gap: 8px;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #014358;
  border-radius: 30px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}

.buttonContainer:hover,
.active {
  background: #014358;
}

.disabled {
  opacity: 0.65;
  cursor: auto !important;
}

.buttonContainer:hover .text,
.active .text {
  color: #ffffff;
  background: #014358;
}

.buttonContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  height: 24px;
  background: rgba(255, 255, 255, 1e-6);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.text {
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #014358;
  flex: none;
  order: 0;
  flex-grow: 0;
}
