@import '../../../../shared.scss';

.circleIcon {
  font-size: 12px;
  line-height: 16px;
  margin-right: 5px;
}

.active {
  color: #9bb94e;
}

.notActive {
  color: #dddddd;
}

.customCard {
  margin-bottom: 40px;
  padding: 40px 15px 10px;
  @include nl-media-lg {
    &:global(.card) {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
    margin-bottom: 0;
  }
}

.tableTitle {
  margin: 50px 15px 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Roboto Slab', serif;
  @include nl-media-lg {
    margin: 15px 15px 15px 0;
    font-size: 18px;
  }
}

.statusContainer {
  display: flex;
  align-items: center;
}

.status {
  text-overflow: ellipsis;
  overflow: hidden;
}

.boldText {
  font-weight: bold;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.customButton {
  @include nl-media-lg {
    margin-top: 5px;
    width: 100%;
  }
}
