:global(#root-panel-search) {
  position: absolute;
  top: 6rem;
  left: 1rem;
}

.container {
  position: relative;
  width: 100%;
  height: calc(100% - 78px);
  flex: 1 1;
}
