.iconContainer:hover,
.selected {
  background: #014358 !important;
}

.iconContainer:hover .icon,
.selected .icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(65deg)
    brightness(102%) contrast(102%);
}

.iconContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 13px;
  gap: 10px;
  width: 38px;
  height: 38px;
  background: rgba(255, 255, 255, 1e-6);
  border: 1px solid #014358;
  border-radius: 30px;
  cursor: pointer;
}

.popupContainer {
  position: absolute;
  z-index: 100;
}

.popupContainerInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 3px;
  position: absolute;
  right: -38px;
  top: 5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.titleContainer {
  padding: 0px 0px 8px;
  gap: 4px;
  height: 32px;
  background: rgba(255, 255, 255, 1e-6);
}

.title {
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #014358;
  white-space: nowrap;
}
