@import '../../theme.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px auto;
}
.logoContainer {
  width: 50%;
  margin: 0 auto;
}

.errorContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorIcon {
  color: $danger;
  font-size: 54px;
  border: 1px solid $danger;
  border-radius: 50%;
  padding: 10px;
}
.verifyText {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c757d;
  margin-top: 12px;
  text-align: center;
}

.verifyTitle {
  font-weight: 500;
  font-size: 22px;
  color: $primary;
}
.emailIcon {
  color: $primary;
  background-color: white;
  font-size: 54px;
  border: 1px solid $primary;
  border-radius: 50%;
  padding: 10px;
}

.emailWarning {
  @extend .verifyText;
  color: $secondary;
  margin-top: 16px;
}
.emailWarningIcon {
  margin-right: 8px;
}
