.pìllsContainer {
  display: flex;
  flex-wrap: wrap;
}

.filterContainer {
  margin-bottom: 15px;
}

.filterCategory {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clearButton {
  text-align: right;
  color: #3794fc;
  font-weight: 500;
  font-size: 12px;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}
