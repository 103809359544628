.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customTypeahead {
  flex: 1;
  :global(.form-control) {
    border: none;
  }
}

.listWrapper {
  margin-top: 10px;
  border: 1px solid #e9e5e5;
  border-radius: 4px;
  :last-child {
    border-bottom: transparent;
  }
}

.addressWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e9e5e5;
}

.customLink {
  align-self: flex-end;
  margin: 15px 0 0 15px;
}
