@import '../../../../shared.scss';

.searchContainer {
  display: flex;
  align-items: center;
  input :global(.form-control) {
    text-align: left;
  }
  @include nl-media-lg {
    flex-wrap: wrap;
  }
}

.label {
  font-weight: bold;
}

.clearIcon {
  cursor: pointer;
  color: #e0e0e0;
  margin: 0 15px 0 5px;
}

.hide {
  visibility: hidden;
}

.customButton {
  @include nl-media-lg {
    margin-top: 5px;
    width: 100%;
  }
}
