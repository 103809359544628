@import '../../shared.scss';

// ShareButton
.shareButton {
  &:global(.btn) {
    font-weight: bold;
    padding: 5px;
    color: #014358;
    &:hover {
      text-decoration: none;
      color: #014358;
    }
    &:focus {
      text-decoration: none;
    }
  }
}

.shareIcon {
  margin-left: 10px;
}

// ShareLink
.inputContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 600px;
}

.innerWrapper {
  display: flex;
  @include nl-media-lg {
    flex-direction: column;
    align-items: stretch;
  }
}

.customInput {
  text-overflow: ellipsis;
  border-width: 0 0 2px 0;
  flex-basis: 45%;
  flex-grow: 1;
  :focus {
    box-shadow: none;
    border-width: 0 0 2px 0;
  }
  &:disabled {
    border-style: dotted;
  }
  &.is-invalid {
    border-width: 0 0 2px 0;
    background: none;
    padding-right: 0.75rem;
    &:focus {
      box-shadow: none;
    }
  }
  @include nl-media-lg {
    margin-bottom: 8px;
  }
}

.copiedMsgWrapper {
  height: 24px;
}

.copiedMsg {
  color: green;
  animation: fadeOut 2s;
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

// ShareModal
.shareModal {
  &:global(.modal) {
    :global(.modal-dialog) {
      max-width: 578px;
    }
    :globla(.modal-header) {
      padding: 18px 30px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.description {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.highlight {
  font-weight: bold;
  margin: 0;
}
