@import '../../shared.scss';

.clientBanner {
  width: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  @include nl-media-lg {
    justify-content: center;
  }
  @include nl-media-md {
    width: 100%;
    display: block;
  }
}

.mainBanner {
  position: relative;
  width: 68%;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  color: #014358;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.9) 27.22%,
    rgba(255, 255, 255, 0.9) 27.23%,
    rgba(255, 255, 255, 0.7) 46.65%,
    rgba(255, 255, 255, 0.4) 67.5%
  );
  background-size: cover;
  @include nl-media-lg {
    display: none;
  }
  .informationToast {
    position: absolute;
    width: auto;
    top: 20px;
    background-color: #fff;
    border-radius: 30px;
    @media screen and (max-width: 1100px) {
      max-width: 600px;
    }
    div {
      color: #014358;
    }
  }
}

.backgroundImage {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../../assets/login-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: -10vw;
  z-index: -1;
  @media screen and (max-width: 1300px) {
    background-position-x: -20vw;
  }
  @media screen and (max-width: 1100px) {
    background-position-x: -35vw;
  }
}

.textContainer {
  h1 {
    max-width: 66%;
    margin-bottom: 10px;
    font-family: 'Roboto Slab';
    font-size: clamp(48px, 3.3vw, 132px);
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    @media screen and (max-width: 1130px) {
      font-size: 40px;
    }
    strong {
      font-weight: 600;
    }
  }
  p {
    max-width: 60%;
    margin-bottom: 10px;
    font-size: clamp(16px, 1.1vw, 44px);
    font-family: 'Roboto';
  }
}

.needslistLogo {
  position: absolute;
  bottom: 50px;
  left: 80px;
  display: flex;
  align-items: center;
  span {
    margin-right: 15px;
    font-style: italic;
    font-size: clamp(16px, 1.1vw, 44px);
    opacity: 0.7;
    color: #014358;
  }
  img {
    width: 14vw;
    height: 10vh;
    min-width: 211px;
    min-height: 38px;
  }
  @include nl-media-lg {
    display: none;
  }
}

.blueStrip {
  position: absolute;
  min-width: 15px;
  width: 0.5%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #014358;
  @include nl-media-lg {
    display: none;
  }
}

.cardContainer {
  &:global(.card) {
    width: 32%;
    min-width: 400px;
    border: none;
    flex-direction: row;
    @include nl-media-lg {
      border: 1px solid #eeeeee;
      border-radius: 0;
    }
    @include nl-media-md {
      height: 100%;
      width: 100%;
      min-width: initial;
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

.formContainer {
  flex: 1;
  padding: 12px 50px 0;
  @include nl-media-lg {
    padding: 12px 25px 0;
  }
  :global(.nav.nav-tabs) {
    width: 57%;
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
    font-size: clamp(16px, 1vw, 44px);
    font-family: 'Roboto', sans-serif;
    :global(.nav-item.nav-link) {
      width: 50%;
      display: inline-block;
      text-align: center;
      font-weight: 500;
      padding: 7px 0;
      color: #014358;
      opacity: 0.5;
      text-transform: capitalize;
    }
    :global(.nav-item.nav-link.active) {
      opacity: 1;
      border-width: clamp(2px, 0.2vw, 4px);
    }
    @include nl-media-lg {
      width: 100%;
      margin: 0 auto 50px;
      justify-content: center;
    }
  }

  :global(.tab-content) {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include nl-media-lg {
      height: auto;
    }
  }
}

.logoContainer {
  width: 50%;
  margin: 0 auto;
}

.spinnerWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.mainForm {
  margin-top: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  :global(.form-check) {
    font-size: clamp(16px, 1vw, 44px);
  }
  :global(.form-check-input) {
    width: clamp(13px, 1vw, 40px);
    height: clamp(13px, 1vw, 40px);
    margin-left: -1.5vw;
    @include nl-media-lg {
      width: auto;
      height: auto;
      margin-left: -20px;
    }
  }
  :global(.invalid-feedback) {
    font-size: clamp(16px, 1vw, 44px);
  }
}

.toast {
  margin-bottom: 15px;
}

.fieldsContainer {
  margin-top: auto;
  :global(.form-group) {
    margin-bottom: 16px;
    padding: 0;
    :global(.form-control) {
      height: clamp(48px, 6vh, 132px);
      padding: 0 clamp(20px, 1.6vw, 64px);
      font-size: clamp(16px, 1vw, 44px);
      border: 1px solid #c2c8d0;
      border-radius: 100px;
      &:focus {
        border-width: 2px;
        border-color: #014358;
      }
    }
  }
  :global(.form-check) {
    margin-top: 16px;
  }
  .navLink {
    margin-top: 24px;
    margin-left: 4px;
    font-weight: normal;
    text-align: right;
    color: #014358;
    font-size: clamp(16px, 1vw, 44px);
    font-weight: 700;
    @include nl-media-lg {
      font-weight: 700;
    }
  }
}

.footer {
  margin-top: 16px;
  text-align: center;
  button {
    width: 100%;
    height: clamp(48px, 6vh, 132px);
    border-radius: 100px;
    font-size: clamp(16px, 1.1vw, 44px);
    font-weight: 400;
  }
}

.formTitle {
  margin: 0 0 12px;
  text-align: center;
  font-size: clamp(22px, 1.5vw, 60px);
  font-family: 'Roboto';
  color: #014358;
}

.formSubTitle {
  margin-bottom: 32px;
  color: #6c757d;
  font-size: clamp(16px, 1.1vw, 44px);
  font-weight: 400;
  font-family: 'Roboto';
  text-align: center;
}
