.formCheckbox {
  flex-direction: column;

  &:global(.form-control) {
    border-color: transparent;
    border-width: 0 0 2px 0;
    height: fit-content;
    flex: 1;
  }
}
