.itemsCard {
  margin-top: 24px;
  padding: 15px;
  border: 1px solid #b84e74;
  border-radius: 15px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
  background-color: transparent;
  span {
    display: block;
  }
  b {
    display: block;
  }

  .cardHeader {
    padding: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    span {
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      color: #979797;
    }
    strong {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .cardTitle {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #b84e74;
  }

  .orgLogo {
    width: 55px;
    height: 55px;
    background-color: #b84e74;
    font-size: 25px;
  }

  .cardBody {
    padding: 20px 0;
    p {
      margin: 0;
    }
    .infoIcon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    .cardButton {
      margin-top: 20px;
      text-transform: uppercase;
      border-color: #b84e74;
      color: #b84e74;
      &:hover,
      &:not(:disabled):active {
        color: #fff;
        border-color: #b84e74;
        background-color: #b84e74;
      }
      &:focus,
      &:not(:disabled):active:focus {
        box-shadow: 0 0 0 0.2rem rgba(212, 25, 172, 0.5);
      }
    }
  }
}
