@import '../../shared.scss';

.form {
  position: relative;
  margin: 20px 0 60px;

  @include nl-media-lg {
    margin-bottom: 30px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;

  @include nl-media-lg {
    justify-content: center;
    margin-top: 20px;
  }
}

.fieldContainer {
  margin: 0 auto;
  width: 460px;

  @include nl-media-lg {
    width: 100%;
  }
}
