@import '../../../../shared.scss';
@import '../../../../theme.scss';

.container {
  position: relative;
  z-index: 30;
  width: 25vw;
  min-width: 300px;
  @include nl-media-lg {
    width: 100%;
    z-index: 40;
  }
  &.listViewContainer {
    @include nl-media-lg {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  &.hidePanel {
    width: 0;
    min-width: 0;
    .innerWrapper {
      display: none;
    }
  }
  .innerWrapper {
    width: 100%;
    height: 100%;
    padding: 20px 15px 0 40px;
    background-color: #f5f9fc;
    :global(.accordion) {
      margin-bottom: 10px;
      :global(.card),
      :global(.card-header) {
        padding: 0;
        border: none;
        background-color: transparent;
      }
      :global(.card) {
        display: flex;
        flex-direction: column-reverse;
        transition: none;
        :global(.show) + :global(.card-header) {
          svg {
            transform: rotate(180deg);
          }
        }
        :global(.collapsing) {
          transition: none;
        }
      }
      :global(.card-header) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        color: $primary;
        cursor: pointer;
        svg {
          width: 13px;
          height: 13px;
        }
      }
      :global(.card-body) {
        padding: 0;
        li {
          margin-top: 10px;
          input {
            box-shadow: none;
          }
        }
      }
    }
  }
  .hideButton {
    position: absolute;
    top: 50%;
    right: -28px;
    width: 28px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left: none;
    background-color: rgba(248, 248, 248, 1);
    cursor: pointer;
    &.listViewHideButton {
      position: fixed;
      top: 60%;
      right: initial;
    }
  }
}
