@import '../../shared.scss';

.wrapper {
  display: flex;
  background-color: white;
  border-radius: 25px;
}

.dropdownTrigger {
  flex: 0.3;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 40px 0 30px;
  font-weight: 600;
  cursor: pointer;
  @include nl-media-lg {
    flex: 0.2;
    padding: 0 10px 0 15px;
  }
}

.searchInput {
  flex: 1;
  :global(.form-control) {
    border: none;
  }
}

.noOptionsContainer {
  flex: 0.3;
  padding: 0 40px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 600;
    margin: 0;
  }
  &.noPadding {
    padding: 0;
  }
}
