.modal {
  :global(.modal-dialog) {
    max-width: 700px;
  }

  :global(.modal-body) {
    padding: 0px;
  }
}

.locationForm {
  padding: 17px 10px;

  label {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }

  :global(.form-control) {
    padding: 0;
    border: none;
    border-bottom: 1px solid #efefef;
    color: #9e9e9e;
    &.editableField {
      color: #000;
      &::placeholder {
        color: #000;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.footerButtons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 12px;
  .modalButton {
    padding: 7px 13px;
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
  }
}
.informationRow {
  padding: 30px 22px;
  display: flex;
  align-items: center;
  background-color: rgba(1, 67, 88, 0.03);
  img {
    height: 32px;
  }
  p {
    margin-bottom: 0;
    margin-left: 8px;
    font-size: 20px;
  }
}

.dataConfirm {
  padding: 30px 17px;
  border: 1px solid #e8e8e8;
  .typePill {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #014358;
    color: white;
    border-radius: 50px;
    width: fit-content;
  }
  .dataLabel {
    font-size: 24px;
    font-weight: 500;
    color: #014358;
    margin: 10px 0;
  }
  .dataLocation {
    border-bottom: 1px dashed #014358;
    padding-bottom: 10px;
    p {
      margin: 0;
      line-height: 1;
    }
    .dataStreet {
      font-size: 20px;
      color: #014358;
    }
  }
  .dataLandmark {
    margin: 10px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    color: #014358;
  }
}
