@import '../../shared.scss';

.topBar {
  position: sticky;
  top: 0;
  z-index: 3000;
  :global(.navbar) {
    height: 4.9rem;
    border-bottom: 0.06rem solid #e8e8e8;
    padding: 8px 20px;
    flex: 1;
    justify-content: space-between;
    @include nl-media-lg {
      justify-content: flex-start;
      position: relative;
      height: 5.5rem;
      align-items: center;
      padding: 0;
    }
    @media screen and (min-width: 1190px) {
      padding: 8px 30px;
    }
  }
  :global(.navbar-toggler) {
    position: relative;
    z-index: 2;
    outline: none;
    padding: 0;
    border: none;
    margin: 0 1rem;
  }
  :global(.navbar-nav) {
    height: 100%;
  }
  :global(.navbar-brand) {
    height: inherit;
  }

  .navBarCollapse {
    :global(.navbar-nav) {
      height: 100%;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      @include nl-media-lg {
        align-items: initial;
        flex-direction: column-reverse;
      }
    }
    &:global(.show) {
      height: calc(100vh - 47.8px);
      overflow: scroll;
    }
  }
}

.profileContainer {
  display: flex;
  @media screen and (max-width: 61.938em) {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    background-color: #014358;
  }
}

.profileMenu {
  &:global(.dropdown) {
    margin-right: 0;
  }
}

.userName {
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
}

.profileImage {
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  margin-right: 0.31rem;
  border-radius: 50%;
  font-size: 0.75rem;
  text-align: center;
  background-color: grey;
  i {
    color: white;
  }

  @media screen and (max-width: 61.938em) {
    width: 4rem;
    height: 4rem;
    margin: 1.875rem 0;
    margin-left: 1.25rem;
    font-size: 1rem;
    line-height: 4rem;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  @media screen and (max-width: 61.938em) {
    margin: 0;
    padding: 1rem 1.56rem;
    border-bottom: 0.063rem solid #d8d8d8;
    font-weight: normal;
  }
}

.dropdownToggle {
  font-size: 0.83rem;
  cursor: pointer;
  @media screen and (max-width: 61.938em) {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1rem;
    color: #636363;
  }
  @media screen and (min-width: 74.4375em) {
    font-size: 1rem;
  }
}

.icon {
  margin-right: 0.31rem;
  @media screen and (max-width: 61.938em) {
    margin-right: 2.5rem;
  }
}

.caretIcon {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
}

.dropdownLink {
  padding: 0 1rem 0.3rem;

  &:hover {
    background-color: #eeeeee;
  }

  svg {
    width: 1.25rem;
    margin-right: 0.62rem;
  }

  @media screen and (max-width: 61.938em) {
    padding: 1rem 0 1rem 4rem;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    color: #636363;
  }
}

.userName {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.dropdownContainer {
  height: 70%;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  color: #636363;
  @media screen and (max-width: 61.938em) {
    flex-direction: column;
    align-items: center;
    height: auto;
    font-weight: normal;
  }
}

.dropdownContainer .dropdownMenu {
  @media screen and (max-width: 61.938em) {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: #eeeeee;
  }
  min-width: 260px;
}

.dropdownButton {
  width: 100%;
  display: block;
  @media screen and (max-width: 61.938em) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

body .dropdownContainer .dropdownHover {
  display: none;
}
body .dropdownContainer:hover .dropdownHover,
body .dropdownContainer:target .dropdownHover {
  display: block;
}
