$need-color: #014358;
$offer-color: #fa812f;

.barChartContainer {
  padding-top: 20px;
  width: 100%;
}

#barChart {
  border-radius: 62px;
  width: 100%;
}

.chartLegendDivider {
  height: 0px;
  opacity: 0.25;
  border: 1px dashed #014358;
}

.chartLegendHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  padding-top: 25px;
}

// Currently unused, can use this when we add back dollar
// value text to visualizations.
.dollarValueText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: #adb5bd;
  font-size: 14px;
}

.legendKeyCircle {
  width: 15px;
  height: 15px;
  border-radius: 30px;
  margin-bottom: 2px;
}

.legendKeysVerticalContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.legendKeysVerticalContainer > * {
  padding-top: 10px;
}

.legendKeysHorizontalContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.legendKeyContainer > * {
  margin-right: 5px;
}

.legendKeyContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}

.legendKeyDisplayText {
  font-family: 'Roboto';
  font-size: 14px;
}

.legendKeyItemCountText {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
}

.pieChartContainer {
  padding: 20px 0 0 25px;
}

.totalPieChartCount {
  position: fixed;
  top: 10px;
  right: 10px;
}

.visualizationCardOuterContainer {
  box-sizing: border-box;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  height: 244px;
  background: #ffffff;
  border: 1px solid rgba(1, 67, 88, 0.25);
  border-radius: 15px;
  height: 100%;
}

.visualizationCardInnerContainer {
  padding: 20px;
  width: 100%;
}

.visualizationCardLeftInnerContainer {
  border-radius: 30px;
  padding: 20px;
  width: 25%;
}

.visualizationCardRightInnerContainer {
  padding: 20px;
  width: 70%;
}

.visualizationCardHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
}

.visualizationsDivider {
  border-top: 1px dashed #014358;
  opacity: 0.25;
  margin: 20px 0;
}

.visualizationVerticalDivider {
  border-right: 1px solid rgba(1, 67, 88, 0.25);
}

.visualizationCardDividedContainer {
  display: flex;
  flex-direction: row;
}
