.spinner {
  display: flex;
  justify-content: center;
}

.panelHeader {
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  padding: 19px 0 11px 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.paginator {
  margin-top: 20px;
  font-size: 14px;
}
