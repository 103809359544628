@import '../../shared.scss';

.bannerWrapper {
  margin-top: 25px;
}

.bannerWrapperV2 {
  margin: 25px 40px 0;
  @include nl-media-lg {
    margin: 30px 15px 0;
  }
}

.customRow {
  &:global(.row) {
    margin: 25px 40px 0;
    @include nl-media-lg {
      margin: 30px 15px 0;
    }
  }
}
