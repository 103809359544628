.container {
  background-color: #fff;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  padding: 19px 103px 11px 15px;
  position: sticky;
  top: 0;
  z-index: 3;
}

.h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
}

.wrapper {
  padding: 29px 18px 31px 18px;
}

.itemHeader {
  margin-bottom: 16px;
}

.itemLocation {
  margin-bottom: 5px;
}

.organizationWrapper {
  cursor: pointer;
}

.division {
  height: 1px;
  background-color: #e8e8e8;
  margin: 0 -18px;
}

.goBack {
  padding: 0;
  border: none;
  font-size: 20px;
  font-weight: 500;
  color: #3794fc;
  background: none;
}
