$primary: #014358;
$secondary: #fa812f;
$success: #8bc34a;
$info: #3794fc;
$danger: #fc381d;
$warning: #f2c200;
$form-border-color: rgba(0, 0, 0, 0.12);
$custom-select-disabled-bg: transparent;
$input-disabled-bg: transparent;
$input-bg: transparent;
$input-focus-bg: transparent;

$font-family-sans-serif: 'Roboto';
$font-family-serif: 'Roboto Slab', serif;
$card-border-radius: 15px;

$body-bg: #f5f9fc;
$body-color: #000;

$btn-padding-y: 7px;
$btn-padding-x: 58px;
$btn-border-radius: 50px;
$btn-font-weight: 600;
$btn-line-height: 1.8;
$btn-border-width: 2px;
$btn-font-size: 1.1rem;

$btn-padding-y-sm: 9px;
$btn-padding-x-sm: 25px;
$btn-border-radius-sm: 50px;
$btn-font-weight-sm: 500;
$btn-line-height-sm: 1em;
$btn-border-width-sm: 1px;
$btn-font-size-sm: 0.875rem;

$input-border-color: $form-border-color;
$input-focus-border-color: $primary;
$input-border-radius: 0;
$input-height-lg: 45px;
$input-font-size-lg: 1em;

$modal-header-padding-x: 32px;
$modal-header-padding-y: 12px;

$custom-select-disabled-color: rgba(0, 0, 0, 0.38);

$nav-tabs-border-color: #eeeeee;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: #000000;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent;
