@import '../../shared.scss';
@import './AddItems.module.scss';

.recentIssuesTitle {
  margin: 38px 0 0 26px;
  font-weight: 500;

  @include nl-media-lg {
    margin: 0;
  }
}

.recentIssuesContainer {
  display: flex;
  flex-wrap: wrap;
}

.recentIssue {
  flex: 1 0;

  & > :global(.card) {
    margin: 10px;
    min-width: 15.62rem;
    min-height: 5.75rem;

    @include nl-media-lg {
      height: auto;
    }
  }
}
