@import '../../../../theme.scss';

.currentFilters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  ul {
    margin: 10px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    li {
      position: relative;
      margin: 5px 5px 5px 0;
      padding: 5px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(1, 67, 88, 0.5);
      border-radius: 20px;
      flex-wrap: wrap;
      list-style: none;
      text-transform: capitalize;
      background-color: transparent;
      div {
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
        svg {
          cursor: pointer;
          g {
            opacity: 1;
          }
        }
      }
    }
  }
}

.filtersHeader {
  display: flex;
  justify-content: space-between;
  color: $secondary;
  cursor: pointer;
  h3 {
    margin-bottom: 0;
    color: $primary;
    font-family: $font-family-serif;
  }
}

.hideFilters {
  height: auto;
  svg {
    margin-right: 5px;
  }
}

.clearAll {
  width: fit-content;
  color: $secondary;
  cursor: pointer;
}

.filter {
  color: $secondary;
  margin-right: 5px;
}

.filterValue {
  color: $primary;
  margin-right: 10px;
}
