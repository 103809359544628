.wrapper {
  :global(.react-datepicker-wrapper) {
    display: flex;
  }
  :global(.react-datepicker__close-icon) {
    display: none;
  }
  :global(.react-datepicker-popper) {
    z-index: 3;
  }
}
