@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

.wrapper {
  margin-bottom: 100px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 60px;
  }
}

.container {
  margin-bottom: 101px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}
