@import '../../../../shared.scss';

.item {
  flex-grow: 0;
  max-width: 285px;
  width: calc(33.3% - 20px);
  &:global(.card) {
    width: 100%;
    height: 322px;
    max-width: none;
    @include nl-media-lg {
      max-width: 700px;
      margin-bottom: 20px;
    }
  }
  @include nl-media-lg {
    margin: 7.5px 0;
    width: 100%;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 7.5px;
    }
  }
  @media screen and (max-width: 639px) {
    max-width: 330px;
    width: 100%;
    margin: 10px 0;
  }
}
