@import '../../shared.scss';

.navLink {
    color: #636363;
    font-weight: 600;

    :hover {
        color: inherit;
        text-decoration: none;
    }

    @media screen and (min-width: 62em) {
        height: 70%;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    @media screen and (min-width: 1190px) {
        margin-right: 1.3rem;
    }

    @include nl-media-lg {     
        font-weight: normal;     
    }
}