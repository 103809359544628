.container {
  position: relative;
}

.icon {
  cursor: pointer;
  color: #e0e0e0;
  margin: 0 15px 0 5px;
  position: absolute;
  right: 0;
  bottom: 10px;
}

.input {
  width: 100%;
  border-radius: 30px;
  border-width: 1px;
  height: 36px;
  padding: 7px 13px 7px 20px;
}
