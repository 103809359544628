.choiceMultipleControl {
  &:global(.form-group) {
    margin-bottom: 0;
  }
}

.customCheck {
  margin-bottom: 2px;
}

.customNavLink {
  text-decoration: underline;
}

.customLink {
  text-decoration: underline;
  color: #636363;
  font-weight: 600;
  ˇ :hover {
    color: inherit;
    text-decoration: none;
  }
}
