@import '../../../../theme.scss';
@import '../../../../shared.scss';

.container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(1, 67, 88, 0.5);
}

.tabs {
  @include nl-media-lg {
    font-size: 14px;
  }
  button {
    padding: 5px 20px;
    border: none;
    font-weight: 500;
    color: $primary;
    background: none;
    opacity: 0.5;
    @include nl-media-lg {
      padding: 5px 10px;
    }
    &:focus {
      outline: none;
    }
    &.isActive {
      border-bottom: 3px solid $primary;
      opacity: 1;
    }
  }
}

.menuOptions {
  display: flex;
  align-items: center;
  @include nl-media-lg {
    font-size: 14px;
  }
  span {
    cursor: pointer;
    svg {
      margin-left: 3px;
    }
  }
}
