@import '../../shared.scss';

.wrapper {
  display: flex;
  @include nl-media-lg {
    justify-content: center;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0;
}

.item {
  flex-grow: 0;

  @include nl-media-lg {
    margin: 7.5px 0;
    width: 100%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 7.5px;
    }
  }
}

.itemWidth {
  max-width: 285px;
  width: calc(33.3% - 20px);
  margin: 10px;
  flex-grow: 0;
  &:global(.card) {
    min-width: 230px;
  }
  @media screen and (max-width: 639px) {
    max-width: 330px;
    width: 100%;
    margin: 10px 0;
  }
}

.rowWrap {
  flex-flow: row wrap;
}

.itemListContainer {
  flex-direction: column;
}

.itemListItem {
  width: auto;
}
